
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
import { get_article_list } from "@/api/article.api";
import ArticleCard from "@/components/ArticleCard.vue";
import { get_user_message } from "@/api/user.api";
@Options({
  components: { ArticleCard },
  data() {
    return {
      article_list: null,
      urlPref: window.location.origin,
      message: "",
    };
  },
  async created() {
    document.title = "leoyi编程日志";
    // 获取最近的几篇文章
    this.article_list = await get_article_list({
      userId: 1,
      num: 4,
      offset: 0,
    });
    this.message = await get_user_message({ userId: 1 });
  },
  methods: {
    // ...mapActions("user", ["requestUserInfo"]),
    gotoSearch() {
      this.$router.push({
        name: "search",
        query: {
          search_all: 1,
        },
      });
    },
  },
})
export default class Home extends Vue {}
